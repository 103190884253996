@import "./vars";

#thankyou {
    min-height: 60vh;
    padding: 100px 10px 0;

    h2.h1 {
        font-size: 42px;
        max-width: 80%;
        margin: auto;
        padding-bottom: 50px;
        font-family: $f1-reg;
    }

    h3 {
        font-family: $f1;
        font-style: italic;
        font-size: 38px;
    }
}

@media (max-width: 910px) {
    #thankyou {    
        h2.h1 {
            font-size: 36px;
        }
    
        h3 {
            font-family: $f1;
            font-style: italic;
            font-size: 32px;
        }
    }
}

@media (max-width: 650px) {
    #thankyou {    
        padding: 50px 10px;
        h2.h1 {
            font-size: 30px;
        }
    
        h3 {
            font-family: $f1;
            font-style: italic;
            font-size: 24px;
            padding-bottom: 25px;
        }
    }
}

@media (max-width: 350px) {
    #thankyou { 
        h2.h1 {
            max-width: 100%;
            font-size: 24px;
        }
    }
}