#blog-posts {
    min-height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .featured_img {
        max-width: 200px;
    }
}
