@import "./vars";

#homepage {
    header {
        background-image: url('https://socialarthouse.s3.us-east-2.amazonaws.com/Logos/Wave-lines.png');
        background-size: cover;
        padding: 160px 40px 40px;
        min-height: 100vh;
        scroll-snap-align: center;

        h2 {
            font-size: 52px;
            text-transform: uppercase;
        }

        h3 {
            font-family: $f1;
            font-size: 32px;
            margin: 60px auto;
            max-width: 1000px;
        }

        .fa-chevron-down {
            font-size: 32px;
            -webkit-animation-duration: 2s;
                    animation-duration: 2s;
            -webkit-animation-iteration-count: infinite;
                    animation-iteration-count: infinite;
            -webkit-transform-origin: bottom;
                -ms-transform-origin: bottom;
                    transform-origin: bottom;
            -webkit-transform: scale(1.05);
                -ms-transform: scale(1.05);
                    transform: scale(1.05);  

            &:hover {
                cursor: pointer;
            }
        }

        .bounce{
            -webkit-animation-name: bounce;
                    animation-name: bounce;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
        }

        @-webkit-keyframes bounce {
            0%   { -webkit-transform: translateY(0); transform: translateY(0); }
            50%  { -webkit-transform: translateY(10px); transform: translateY(10px); }
            100% { -webkit-transform: translateY(0); transform: translateY(0); }
        }

        @keyframes bounce {
            0%   { -webkit-transform: translateY(0); transform: translateY(0); }
            50%  { -webkit-transform: translateY(10px); transform: translateY(10px); }
            100% { -webkit-transform: translateY(0); transform: translateY(0); }
        }
    }

    main {
        section {
            padding: 60px 20px;
            scroll-snap-align: center;

            h3 {
                font-size: 48px;
            }

            .fa-chevron-down  {
                font-size: 32px;
                -webkit-animation-duration: 2s;
                        animation-duration: 2s;
                -webkit-animation-iteration-count: infinite;
                        animation-iteration-count: infinite;
                -webkit-transform-origin: bottom;
                    -ms-transform-origin: bottom;
                        transform-origin: bottom;
                -webkit-transform: scale(1.05);
                    -ms-transform: scale(1.05);
                        transform: scale(1.05);  
    
                &:hover {
                    cursor: pointer;
                }
            }
    
            .bounce{
                -webkit-animation-name: bounce;
                        animation-name: bounce;
                -webkit-animation-timing-function: ease;
                        animation-timing-function: ease;
            }
    
            @-webkit-keyframes bounce {
                0%   { -webkit-transform: translateY(0); transform: translateY(0); }
                50%  { -webkit-transform: translateY(10px); transform: translateY(10px); }
                100% { -webkit-transform: translateY(0); transform: translateY(0); }
            }
    
            @keyframes bounce {
                0%   { -webkit-transform: translateY(0); transform: translateY(0); }
                50%  { -webkit-transform: translateY(10px); transform: translateY(10px); }
                100% { -webkit-transform: translateY(0); transform: translateY(0); }
            }
        }

        #about {
            background: $p2;

            h3 {
                max-width: 1000px;
                margin: auto;
            }

            ul {
                margin-top: 60px;
                display: -ms-grid;
                display: grid;
                -ms-grid-columns: 1fr 1fr 1fr;
                grid-template-columns: repeat(3, minmax(0, 1fr));
                max-width: 2000px;
                margin: 60px auto 0;

                li {
                    padding: 40px;

                    &:nth-child(2) {
                        border-right: solid 1px $black;
                        border-left: solid 1px $black;
                    }

                    h4 {
                        font-size: 32px;
                        font-family: $f1-bold;
                        margin-bottom: 20px;
                    } 
                    
                    p {
                        text-align: left;
                    }
                }
            }

            #about-down-arrow {
                margin-top: 40px;
            }
        }   

        #contact {
            background: $t3;
            padding-bottom: 80px;
            
            div {
                margin-top: 60px;
            }
        }
    }
}



@media(max-width: 800px) {
    #homepage {
        header {
            h3 {
                font-size: 28px;
            }
        }

        main {
            section {
                h3 {
                    font-size: 42px;
                }
            }
            #about {
                ul {
                    li {
                        padding: 40px 20px;
                        h4 {
                            font-size: 26px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1080px) {
    #homepage {
        .video-wrap {
            video {
                object-fit: contain !important;
            }
        }
    }
}

@media (max-width: 600px) {
    #homepage header {
        padding-top: 60px
    }
}

@media(max-width: 550px) {
    #homepage {
        header {
            h2 {
                font-size: 38px;
            }

            h3 {
                font-size: 22px;
                position: relative;
                bottom: 20px;
                margin-bottom: 20px;
            }
        }

        main {
            section {
                padding: 60px 10px;

                h3 {
                    font-size: 32px;
                }
            }

            #about {
                h3 {
                    font-size: 28px;
                }

                ul {
                    display: block;
                    margin-top: 20px;

                    li {
                        &:nth-child(2) {
                            border-right: none;
                            border-left: none;
                            border-top: 1px solid $black;
                            border-bottom: 1px solid $black;
                        }

                        h4 {
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }
}

@media(max-width: 390px) {
    #homepage {
        header {
            h2 {
                font-size: 34px;
            }

            h3 {
                font-size: 18px;
                position: relative;
                bottom: 20px;
                margin-bottom: 20px;
            }
        }
    }
}