@import "./vars";

#privacy-policy {
    padding: 90px 20px;

    .container {
        text-align: left;
        max-width: 1200px;
        margin: 40px auto;
    }

    h2 {
        font-size: 48px;
        margin-bottom: 25px;
    }

    h3 {
        font-size: 28px;
        font-family: $f1;
        margin-bottom: 10px;
    }

    p {
        margin-bottom: 10px;
    }

    a {
        color: $t1;

        &:hover {
            color: $p1;
        }
    }

    li {
        list-style-type: circle;
        margin: 0 15px;
    }

    .margin-top {
        margin-top: 10px;
    }

    @media (max-width: 700px) {
        padding: 40px 20px;

        h2 {
            font-size: 40px;
        }
        
        h3 {
            font-size: 20px;
        }
    }

    @media (max-width: 400px) {
        h2 {
            font-size: 32px;
        }
    }
}