@font-face {
    font-family: "Manhattan";
    src: url('../fonts/Manhattan-Regular.otf');
}
  
@font-face {
    font-family: "Lato";
    src: url('../fonts/Lato-Light.otf');
}

@font-face {
    font-family: "Lato-Reg";
    src: url('../fonts/Lato-Regular.ttf');
}

@font-face {
    font-family: "Lato-Bold";
    src: url('../fonts/Lato-Bold.ttf');
}

$white: #FFF;
$black: #000;
$p1: #814A3D;
$p2: #DCCEC8;
$t1: #DC997D;
$t2: #F0EFE9;
$t3: #F2F2F2;
$t4: #D9D9D9;
$t5: #C3C3C3;

$f1: "Lato", sans-serif;
$f1-bold: "Lato-Bold", sans-serif;
$f1-reg: "Lato-Reg", sans-serif;
$f2: "Manhattan", serif;
$f3: "futura-pt", sans-serif;