@import "./vars";

// Colors
.grey { background: $t4; width: 350px;}
  
.tan { background: $p2; width: 350px;}

.orange { background: $t1; width: 350px;}

.brown { background: $p1; width: 350px;}

.black { background: $black; width: 350px;}

.small { height: 120px; }

.medium { height: 180px; }

.large { height: 280px; }

#artists {
    text-align: center;
    width: 100%;
    margin: auto;
    background: $black;

    .section_title {
        color: $white;
    }

    h3 {
        margin: 40px 0 100px;
    }

    ul {
        max-width: 1240px;
        margin: auto;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
        grid-gap: 20px;

        .card {
            width: 290px;
            height: 355.67px;
            margin: auto;
    
            a {
                .wrap {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-orient: vertical;
                    -webkit-box-direction: normal;
                        -ms-flex-direction: column;
                            flex-direction: column;
                    -webkit-box-align: center;
                        -ms-flex-align: center;
                            align-items: center;
                    position: relative;
                    -webkit-column-break-inside: avoid;
                    page-break-inside: avoid;
                    -moz-column-break-inside: avoid;
                         break-inside: avoid;
                    -webkit-transition: .6s ease-out;
                    -o-transition: .6s ease-out;
                    transition: .6s ease-out;
    
                    .image-wrap {
                        img {
                            width: 290px;
                        }
                    }
                    .text-wrap {
                        position: absolute;
                        top: 0;
                        left: 0;
                        opacity: 0;
                        height: 100%;
                        width: 100%;
                        text-align: center;
                        -webkit-transition: .45s ease-in-out;
                        -o-transition: .45s ease-in-out;
                        transition: .45s ease-in-out;
                        background: #dccec86f;

                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-orient: vertical;
                        -webkit-box-direction: normal;
                        -ms-flex-direction: column;
                            flex-direction: column;
                        -webkit-box-pack: center;
                        -ms-flex-pack: center;
                            justify-content: center;
    
                        h4 {
                            text-transform: uppercase;
                            font-family: $f1-bold;
                            color: $white;
                            z-index: 4;
            
                            &:nth-child(1) {
                                font-size: 26px;
                            }
            
                            &:nth-child(2) {
                                font-size: 18px;
                            }
                        }
                    }
                    .overlay {
                        position: absolute;
                        width: 290px;
                        height: 100%;
                        background: rgba(0, 0, 0, 0.14);
                        cursor: pointer;
                    }

                    &:hover img {
                        opacity: 0.5;
                    }
        
                    &:hover .text-wrap {
                        opacity: .9;
                        -webkit-box-shadow: 0px 2px 20px #d9d9d940;
                        box-shadow: 0px 2px 20px #d9d9d940;
                    }
                }
            }
        }
    }

    .btn-wrap {
        margin-top: 50px;
    }
}

@media (max-width: 794px) {
    .spacer {
        display: none;
    }
}

@media (max-width: 550px) {
    #artists {
        h3 {
            margin: 20px 0 80px;
        }
    }
}

@media(max-width: 390px) {
    #artists {
        ul {
            .card {
                width: 260px;
                a {
                    .wrap {
                        .image-wrap {
                            img {
                                width: 260px;
                            }
                        }
                        .text-wrap {
                            width: 260px;
                            h4 {
                                &:nth-child(1) {
                                    font-size: 22px;
                                    bottom: 35px;
                                }
    
                                &:nth-child(2) {
                                    font-size: 16px;
                                    bottom: 10px;
                                }
                            }
                        }

                        .overlay {
                            width: 260px;
                        }
                    }
                }
            }
        }
    }
}