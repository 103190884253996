@import "./vars";

#not-found-page {
    padding: 50px 20px;
    min-height: 70vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    background-image: url('https://socialarthouse.s3.us-east-2.amazonaws.com/Logos/Wave-lines.png');
    background-size: cover;

    h2 {
        font-size: 32px;
        margin-bottom: 70px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        font-family: $f1;

        span {
            font-size: 60px;
            font-family: $f1-bold;
            margin-bottom: 20px;
        }
    }
}