@import "./vars";

#footer {
    width: 100%;
    background: $black;
    padding: 100px 40px;
    border-top: 1px solid $white;

    footer {
        max-width: 1500px;
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 3fr 1fr;
        grid-template-columns: 3fr 1fr;
        margin: auto;

        .left {
            display: -ms-grid;
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

            .column {
                margin-bottom: 20px;

                div {
                    text-align: left;
                    margin-bottom: 15px;
                    p {
                        font-size: 20px;
                        font-family: $f1-reg;
                        color: $white;
                    }
    
                    .state {
                        text-decoration: underline;
                        margin-bottom: 10px;
                        color: $white;
                    }
    
                    a.email {
                        color: $white;
                        font-size: 14px;

                        &:hover {
                            color: $t5;
                        }
                    }
                }
            }
        }
    

        .right {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: end;
            -ms-flex-pack: end;
            justify-content: flex-end;
            max-width: 400px;

            div {
                max-width: 300px;
                text-align: left;

                p {
                    color: $white;
                    font-size: 22px;
                    margin-bottom: 40px;
                }

                a {
                    color: $white;
                    font-size: 20px;
                    font-family: $f1-reg; 

                    &:hover {
                        color: $t5;
                    }

                    &.link {
                        font-size: 16px;
                    }
                }

                div.cb-container {
                    margin-bottom: 40px;

                    .contact-btn {
                        background: $p2;
                        padding: 10px 15px;
                        border-radius: 8px;
                        color: $white;
                        color: $black;
                        font-family: $f1-reg;
                        font-size: 18px;
                        text-transform: uppercase;

                        &:hover {
                            background: $white;
                        }
                    }
                }
            }

            #copyright {
                margin-top: 12px;
                color: $white;

                small, a { font-size: 14px; }
            }

            .logo {
                max-width: 150px;
                border-radius: 15px;
                margin-top: 40px;
                cursor: pointer;
            }
        }
    }
}

@media (max-width: 679px) {
    #footer {
        padding: 40px 20px;
        footer {
            .left {
                .column {
                    div {
                        .state {
                            margin-bottom: 10px;
                        }

                        p {
                            font-size: 18px;
                        }
                    }
                }
            }

            .right {
                div {
                    p {
                        font-size: 18px;
                    }

                    div.cb-container {
                        .contact-btn {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 550px) {
    #footer {
        padding-bottom: 0;
        footer {
            display: block;

            .left {
                .column {
                    max-width: 100px;
                    div {
                        .state {
                            margin-bottom: 10px;
                        }

                        p {
                            font-size: 18px;
                        }
                    }
                }
            }

            .right {
                max-width: 100%;
                margin-top: 50px;
                -webkit-box-pack: start;
                -ms-flex-pack: start;
                justify-content: flex-start;
            }
        }
    }
}