@import "./vars";

#team {
    div.container {
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
        padding: 60px 0 0;
        max-width: 2000px;
        margin: 60px auto 0;

        .card {
            margin-bottom: 50px;
            
            img {
                height: 200px;
                border-radius: 50%;
            }

            h4 {
                font-family: $f1-bold;
                margin: 5px 0;
            }

            h5 {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: center;
                    -ms-flex-pack: center;
                        justify-content: center;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                        flex-direction: column;
            }
        }
    }
}

@media(max-width: 1100px) {
    #team {
        div.container {
            -ms-grid-columns: 1fr 1fr;
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

@media(max-width: 500px) {
    #team {
        div.container {
            -ms-grid-columns: 1fr;
            grid-template-columns: repeat(1, 1fr);
        }
    }
}