@import "./vars";

#services {
    min-height: 100vh;
    .list {
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 1fr 1fr;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        max-width: 2000px;
        margin: 50px auto;
        padding: 50px;

        .item.show {
            background: $t1;
        }

        .item {
            padding: 40px;
            -webkit-transition: all ease 0.3s;
            -o-transition: all ease 0.3s;
            transition: all ease 0.3s;
            min-height: 350px;

            ul {
                display: block;
                text-align: left;
                max-width: 400px;
                margin: 40px auto 0;

                li {
                    margin: 20px;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                        -ms-flex-align: center;
                            align-items: center;

                    i {
                        margin-right: 8px;
                    }
                }
            }

            &:nth-child(1) {
                border-bottom: 1px solid $black;
                border-right: 1px solid $black;
            }

            &:nth-child(2) {
                border-bottom: 1px solid $black;
            }
            
            &:nth-child(3) {
                border-right: 1px solid $black;
            }
            
            h4 {
                font-size: 30px;
                font-family: $f1-bold;
            }

            p {
                text-align: left;
                padding: 20px 0;
                max-width: 500px;
                margin: auto;
            }

            button {
                border: none;
                background: none;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: baseline;
                    -ms-flex-align: baseline;
                        align-items: baseline;
                margin-top: 20px;
                margin-left: auto;
                font-family: $f1-bold;

                .fa-chevron-right {
                    font-size: 10px;
                    margin-left: 5px;
                }
            }
        }
    }
}

// animation
.flip {
    -webkit-animation: flip 0.5s linear both;
            animation: flip 0.5s linear both;
}

@-webkit-keyframes flip {
    0% {
        -webkit-transform: scale(1) rotate3d(-1, 1, 0, 0deg);
                transform: scale(1) rotate3d(-1, 1, 0, 0deg);
        -webkit-transform: rotateY(0);
                transform: rotateY(0);
    }
    50% {
        -webkit-transform: rotateY(90deg);
                transform: rotateY(90deg);
    }
    100% {
        -webkit-transform: rotateY(0);
                transform: rotateY(0);
    }
}

@keyframes flip {
    0% {
        -webkit-transform: scale(1) rotate3d(-1, 1, 0, 0deg);
                transform: scale(1) rotate3d(-1, 1, 0, 0deg);
        -webkit-transform: rotateY(0);
                transform: rotateY(0);
    }
    50% {
        -webkit-transform: rotateY(90deg);
                transform: rotateY(90deg);
    }
    100% {
        -webkit-transform: rotateY(0);
                transform: rotateY(0);
    }
}

@media(max-width: 1200px) {
    #services {
        .list {
            .item {
                h4 {
                    font-size: 26px;
                }
            }
        }
    }
}

@media(max-width: 850px) {
    #services {
        h3 {
            margin-bottom: 40px;
        }

        .list {
            display: block;
            margin: 0 -10px 0;
            background: none;

            .item {        
                width: 90%;
                margin: auto;
                padding: 40px 20px;

                div {
                    ul {
                        li {
                            padding: 5px;
                            margin: 5px;
                        }
                    }
                }

                &:nth-child(1) {
                    border-right: none;
                }

                &:nth-child(3) {
                    border-right: none;
                    border-bottom: solid 1px $black;
                }
            }
        }
    }
}

@media(max-width: 550px) {
    #services {
        .list {
            padding: 0;
            .item {

                h4 {
                    font-size: 20px;
                }
            }
        }
    }
}