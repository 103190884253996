@import "./vars";

* {
  font-family: $f1;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

body {
  margin: 0;
  scroll-behavior: smooth;
}

.App {
  text-align: center;
  scroll-behavior: smooth;
  -ms-scroll-snap-type: y mandatory;
      scroll-snap-type: y mandatory;
}

h1, h2, h3, h4, h5, ul, p, a {
  margin: 0;
  padding: 0;
}

h1, h2, h3 {
  font-family: $f2;
}

a {
  color: $black;
  text-decoration: none;
}

li {
  list-style-type: none;
}

button {
  background: none;
  outline: unset;
  border: unset;

  &:hover {
    cursor: pointer;
  }
}

.btn {
  background: $black;
  color: $white;
  border-radius: 8px;
  padding: 10px 20px;
  border: none;
  font-size: 16px;
  position: relative;
  bottom: 5px;
  cursor: pointer;

  &:hover {
    background: $p1;
  }
}

.btn-big {
  background: $black;
  color: $white;
  border-radius: 8px;
  padding: 20px 60px;
  border: none;
  font-size: 18px;
  cursor: pointer;

  &:hover {
    background: $p1;
  }
}

.fade-in {
  -webkit-transition: opacity 1s ease;
  -o-transition: opacity 1s ease;
  transition: opacity 1s ease;
}

.hidden {
  display: none;
}

/* Loader Aninimation */
.loader {
  display: flex;
  justify-content: center;
  margin: 50px 0;
  font-size: 75px;
}

.spinner {
  display: inline-block;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  border: 16px solid $t1;
  border-top: 16px solid $p2;
  animation: spin 2s infinite;
}

@keyframes spin {
  100%{
    transform: rotate(360deg);
  }
}

@media (max-width: 550px) {
  .btn {
    font-size: 14px;
    padding: 10px 15px;
  }

  .btn-big {
    padding: 15px 30px;
  }
}