@import "./vars";

#contact-form {
    min-height: 80vh;
    padding: 50px 0 40px;
    background-image: url('https://socialarthouse.s3.us-east-2.amazonaws.com/Logos/Wave-lines.png');
    background-size: cover;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    div.container {
        max-width: 30%;
        padding-top: 90px;
        margin-left: auto;
        text-align: left;
    }

    h2 {
        font-size: 48px;
        margin-bottom: 25px;
    }

    h3 {
        font-size: 28px;
        font-family: $f1;
    }

    form {
        display: block;
        width: 50%;
        max-width: 700px;
        margin: 50px auto;
        padding: 25px 0 25px;
        border-radius: 2px;
        background: $p2;
        -webkit-box-shadow: 0 4px 20px rgb(0 0 0 / 10%);
                box-shadow: 0 4px 20px rgb(0 0 0 / 10%);
        

        .label {
            margin: 10px auto 5px;
            padding: 0 5px;
            text-align: left;
            width: 70%;
        }

        label {
            font-size: 18px;
            font-family: $f1-reg;
        }

        input {
            outline: none;
            border: solid 1px $black;
            border-radius: 2px;
            padding: 10px;
            margin: 10px 0;
            font-size: 18px;
            width: 80%;
        }

        textarea {
            width: 80%;
            margin-top: 10px;
            font-size: 18px;
            padding: 10px;
        }

        input::-webkit-input-placeholder {
            text-transform: uppercase;
            font-size: 14px;
            display: -webkit-box;
            display: flex;
            -webkit-box-align: center;
                    align-items: center;
            -webkit-box-pack: center;
                    justify-content: center;
        }

        input::-moz-placeholder {
            text-transform: uppercase;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        input:-ms-input-placeholder {
            text-transform: uppercase;
            font-size: 14px;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-align: center;
                align-items: center;
            -ms-flex-pack: center;
                justify-content: center;
        }

        input::-ms-input-placeholder {
            text-transform: uppercase;
            font-size: 14px;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-align: center;
                align-items: center;
            -ms-flex-pack: center;
                justify-content: center;
        }

        input::placeholder {
            text-transform: uppercase;
            font-size: 14px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            -webkit-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;
        }
        
        textarea::-webkit-input-placeholder {
            text-transform: uppercase;
            font-size: 16px;
            display: -webkit-box;
            display: flex;
            -webkit-box-align: center;
                    align-items: center;
            -webkit-box-pack: center;
                    justify-content: center;
        }
        
        textarea::-moz-placeholder {
            text-transform: uppercase;
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        
        textarea:-ms-input-placeholder {
            text-transform: uppercase;
            font-size: 16px;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-align: center;
                align-items: center;
            -ms-flex-pack: center;
                justify-content: center;
        }
        
        textarea::-ms-input-placeholder {
            text-transform: uppercase;
            font-size: 16px;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-align: center;
                align-items: center;
            -ms-flex-pack: center;
                justify-content: center;
        }
        
        textarea::placeholder {
            text-transform: uppercase;
            font-size: 16px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            -webkit-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;
        }

        .btn {
            margin-top: 40px;
        }

        .error {
            text-align: left;
            width: 80%;
            margin: auto;
            font-size: 1rem;
        }
    }
}

@media (max-width: 700px) {
    #contact-form {
        display: block;
        padding: 40px 20px;

        div.container {
            max-width: 100%;
            padding-top: 20px;
            text-align: center;
        }
    
        h2 {
            font-size: 40px;
            margin-bottom: 25px;
        }
    
        h3 {
            font-size: 20px;
        }
    
        form {
            width: 100%;
            max-width: 700px;
            margin: 50px auto;
            padding: 25px 0 25px;
            border-radius: 2px;
            
    
            .label {
                margin: 10px auto 5px;
                padding: 0 5px;
                text-align: left;
                width: 80%;
            }
    
            label {
                font-size: 18px;
                font-family: $f1-reg;
            }
    
            input {
                outline: none;
                border: solid 1px $black;
                border-radius: 2px;
                padding: 10px;
                margin: 10px 0;
                font-size: 18px;
                width: 80%;
            }
    
            textarea {
                width: 80%;
                margin-top: 10px;
                font-size: 18px;
                padding: 10px;
            }
    
            input::-webkit-input-placeholder {
                text-transform: uppercase;
                font-size: 14px;
                display: -webkit-box;
                display: flex;
                -webkit-box-align: center;
                        align-items: center;
                -webkit-box-pack: center;
                        justify-content: center;
            }
    
            input::-moz-placeholder {
                text-transform: uppercase;
                font-size: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
    
            input:-ms-input-placeholder {
                text-transform: uppercase;
                font-size: 14px;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-align: center;
                    align-items: center;
                -ms-flex-pack: center;
                    justify-content: center;
            }
    
            input::-ms-input-placeholder {
                text-transform: uppercase;
                font-size: 14px;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-align: center;
                    align-items: center;
                -ms-flex-pack: center;
                    justify-content: center;
            }
    
            input::placeholder {
                text-transform: uppercase;
                font-size: 14px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                    -ms-flex-align: center;
                        align-items: center;
                -webkit-box-pack: center;
                    -ms-flex-pack: center;
                        justify-content: center;
            }
            
            textarea::-webkit-input-placeholder {
                text-transform: uppercase;
                font-size: 16px;
                display: -webkit-box;
                display: flex;
                -webkit-box-align: center;
                        align-items: center;
                -webkit-box-pack: center;
                        justify-content: center;
            }
            
            textarea::-moz-placeholder {
                text-transform: uppercase;
                font-size: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            
            textarea:-ms-input-placeholder {
                text-transform: uppercase;
                font-size: 16px;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-align: center;
                    align-items: center;
                -ms-flex-pack: center;
                    justify-content: center;
            }
            
            textarea::-ms-input-placeholder {
                text-transform: uppercase;
                font-size: 16px;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-align: center;
                    align-items: center;
                -ms-flex-pack: center;
                    justify-content: center;
            }
            
            textarea::placeholder {
                text-transform: uppercase;
                font-size: 16px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                    -ms-flex-align: center;
                        align-items: center;
                -webkit-box-pack: center;
                    -ms-flex-pack: center;
                        justify-content: center;
            }
    
            .btn {
                margin-top: 40px;
            }
        }
    }
}

@media (max-width: 550px) {
    #contact-form {
        padding-top: 50px;

        h2 {
            font-size: 40px;
        }

        form {
            padding: 10px 0;
            margin-bottom: 5px;
            .label {
                width: 90%;
                label {
                    font-size: 16px;
                }
            }
    
    
            input {
                width: 90%;
                font-size: 16px;
                margin: 5px 0;
            }

            textarea {
                width: 90%;
                font-size: 16px;
            }

            .btn {
                margin-top: 20px;
            }
        }
    }
}