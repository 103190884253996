@import "./vars";

#partners {
    background: $black;
    color: $white;
    padding-bottom: 20px;
    
    h3 {
        margin-bottom: 50px;
    }

    .container {
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 1fr 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-row-gap: 100px;
        max-width: 1000px;
        margin: 120px auto 60px;

        div {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            -webkit-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;
            a {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;  
                -webkit-box-align: center;  
                    -ms-flex-align: center;  
                        align-items: center;
                -webkit-box-pack: center;
                    -ms-flex-pack: center;
                        justify-content: center;
                max-width: 150px;

                .partner-logo {
                    max-width: 150px;
                }
            }
        }
    }

    .content {
        height: 0px;
        overflow: hidden;
        -webkit-transition: height ease 0.5s;
        -o-transition: height ease 0.5s;
        transition: height ease 0.5s;
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
        grid-row-gap: 100px;
        max-width: 1000px;
        margin: 120px auto 60px;

        div {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            -webkit-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;
            a {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;  
                -webkit-box-align: center;  
                    -ms-flex-align: center;  
                        align-items: center;
                -webkit-box-pack: center;
                    -ms-flex-pack: center;
                        justify-content: center;
                max-width: 150px;
    
                .partner-logo {
                    max-width: 150px;
                }
            }
        }
    }

    button {
        background: none;
        color: $white;
        border: none;
        font-size: 18px;
        font-family: $f1-reg;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        margin: 40px auto 0;

        #partners-down-arrow {
            font-size: 18px;
            -webkit-animation-duration: 0;
                    animation-duration: 0;
            -webkit-animation-iteration-count: 0;
                    animation-iteration-count: 0;
        }

        &:hover #down-arrow {
            cursor: pointer;
            -webkit-animation-duration: 2s;
                    animation-duration: 2s;
            -webkit-animation-iteration-count: infinite;
                    animation-iteration-count: infinite;
            -webkit-transform-origin: bottom;
                -ms-transform-origin: bottom;
                    transform-origin: bottom;
            -webkit-transform: scale(1.05);
                -ms-transform: scale(1.05);
                    transform: scale(1.05);
        }
        
        .bounce{
            -webkit-animation-name: bounce;
                    animation-name: bounce;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
        }

        @-webkit-keyframes bounce {
            0%   { -webkit-transform: translateY(0); transform: translateY(0); }
            50%  { -webkit-transform: translateY(10px); transform: translateY(10px); }
            100% { -webkit-transform: translateY(0); transform: translateY(0); }
        }

        @keyframes bounce {
            0%   { -webkit-transform: translateY(0); transform: translateY(0); }
            50%  { -webkit-transform: translateY(10px); transform: translateY(10px); }
            100% { -webkit-transform: translateY(0); transform: translateY(0); }
        }
    }
}

@media (max-width: 750px){
    #partners {
        .container {
            -ms-grid-columns: 1fr 1fr;
            grid-template-columns: 1fr 1fr;
        }

        .content {
            -ms-grid-columns: 1fr 1fr;
            grid-template-columns: 1fr 1fr;
        }
    }
}
 
@media (max-width: 500px) {
    #partners {
    
        .container {
            grid-row-gap: 60px;
            padding-top: 0;

            a {
                .partner-logo {
                    max-width: 75px;
                }
            }
        }

        .content {
            grid-row-gap: 60px;
            padding-top: 0;
            margin-top: 0;

            a {
                .partner-logo {
                    max-width: 75px;
                }
            }
        }
    }
}